exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-animated-online-learning-js": () => import("./../../../src/pages/animated-online-learning.js" /* webpackChunkName: "component---src-pages-animated-online-learning-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-post-page-js": () => import("./../../../src/pages/post-page.js" /* webpackChunkName: "component---src-pages-post-page-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sr-animirano-online-ucenje-js": () => import("./../../../src/pages/sr/animirano-online-ucenje.js" /* webpackChunkName: "component---src-pages-sr-animirano-online-ucenje-js" */),
  "component---src-pages-sr-index-js": () => import("./../../../src/pages/sr/index.js" /* webpackChunkName: "component---src-pages-sr-index-js" */),
  "component---src-pages-sr-konsalting-js": () => import("./../../../src/pages/sr/konsalting.js" /* webpackChunkName: "component---src-pages-sr-konsalting-js" */),
  "component---src-pages-sr-kontakt-js": () => import("./../../../src/pages/sr/kontakt.js" /* webpackChunkName: "component---src-pages-sr-kontakt-js" */),
  "component---src-pages-sr-o-nama-js": () => import("./../../../src/pages/sr/o-nama.js" /* webpackChunkName: "component---src-pages-sr-o-nama-js" */),
  "component---src-pages-sr-politika-privatnosti-js": () => import("./../../../src/pages/sr/politika-privatnosti.js" /* webpackChunkName: "component---src-pages-sr-politika-privatnosti-js" */),
  "component---src-pages-sr-postovi-js": () => import("./../../../src/pages/sr/postovi.js" /* webpackChunkName: "component---src-pages-sr-postovi-js" */),
  "component---src-pages-sr-svedocenja-js": () => import("./../../../src/pages/sr/svedocenja.js" /* webpackChunkName: "component---src-pages-sr-svedocenja-js" */),
  "component---src-pages-sr-uslovi-poslovanja-js": () => import("./../../../src/pages/sr/uslovi-poslovanja.js" /* webpackChunkName: "component---src-pages-sr-uslovi-poslovanja-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

